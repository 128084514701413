import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../../common/constants';
import { ListPage } from './list/list.screen';
import { AI_TALKBOT_PATH, AI_TALKBOT_REPORT_PATH, SALESKIT_BASE_PATH } from 'src/app/modules/sales-kit/constants';

const TalkbotReportRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${SALESKIT_BASE_PATH}${AI_TALKBOT_PATH}${AI_TALKBOT_REPORT_PATH}`}
        to={`${SALESKIT_BASE_PATH}${AI_TALKBOT_PATH}${AI_TALKBOT_REPORT_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${AI_TALKBOT_PATH}${AI_TALKBOT_REPORT_PATH}/list`}
        component={ListPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default TalkbotReportRoutes;
