import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useList } from './list.hook';
import { getReportListColumn } from '../../../util/report-list.util';
import { ReportFilterComponent } from './components/report-filter/report-filter.component';
import { useDispatch } from 'react-redux';
import PruTable from 'src/app/common/components/PruTable/PruTable';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const ListPage: React.FC<ParamsProps> = (props: ParamsProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });

  // custom-hook
  const {
    unicodeList,
    reportListData,
    loading,
    sendReportLoading,
    filterState,
    downloadReport,
    onReset,
    changeFilterState,
    fetchList,
    checkFilter,
  } = useList();

  return (
    <>
      <ReportFilterComponent
        filterState={filterState}
        unitCodeList={unicodeList ?? []}
        onSearch={(params) => {
          if (checkFilter(params)) {
            fetchList(params, dispatch);
          }
        }}
        onReset={onReset}
        changeFilterState={changeFilterState}
      />

      <PruTable
        title={Translation('talkbot.billing_report_list')}
        disableBulkSelect
        disableRefresh
        headerBtnDef={[
          {
            color: 'secondary',
            title: Translation('app.button.download'),
            onClick: () => {
              if (checkFilter(filterState)) {
                downloadReport(filterState, dispatch);
              }
            },
            disabled: sendReportLoading,
          },
        ]}
        operationDef={[]}
        columnDef={getReportListColumn({ Translation })}
        isLoading={loading}
        dataSource={reportListData?.docs}
        defaultRowsPerPage={20}
        noRecordMsg={Translation('talkbot.report.list.empty_msg')}
        emptyColSpan={14}
        totalRecords={reportListData?.totalDocs}
        onChangePage={(page, rowsPerPage) => {
          fetchList({ ...filterState, page, limit: rowsPerPage }, dispatch);
        }}
      />
    </>
  );
};
