import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { PaginateList } from 'src/app/common/types';
import { getQueryPath } from 'src/app/common/utils';
import { TalkbotReportFilterType, TalkbotReportListItem, TalkbotReportListParam } from '../types/talkbot-report-type';
import { PruFilterDropdownItem } from 'src/app/common/components/PruTable/PruFilter';
import { formatFilterState } from '../util/report-list.util';

const talkbotEndpoint = 'talkbot-export-log';

export const fetchUnitCodes = async (dispatch?: Dispatch<any>): Promise<PruFilterDropdownItem[]> => {
  const url = `${ADMIN_URL}/${talkbotEndpoint}/unit-codes`;
  return apiClient
    .get<any>(url)
    .then((response) => {
      const { unit } = response.data ?? {};
      return unit?.map((item: string) => ({ displayName: item, value: item }));
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchTalkbotReportList = async (
  params: TalkbotReportListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<TalkbotReportListItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/${talkbotEndpoint}/billing-report?`, formatFilterState(params));
  return apiClient
    .get<any>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const sendReport = async (body: TalkbotReportFilterType, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${talkbotEndpoint}/send-report`, formatFilterState(body))
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
