import { getDayEnd, getDayStart, getDefaultDisplayDate, preFillZero } from 'src/app/common/utils';
import { PruTableColumnDef } from 'src/app/common/components/PruTable/PruTable';
import { TalkbotReportFilterType, TalkbotReportListItem, TalkbotReportListParam } from '../types/talkbot-report-type';
import dayjs from 'dayjs';
import { removeFirstLetterIfU } from 'src/app/modules/AgencyCampaign/utils/common-utils'; // PACS Customization | PACSSGPPFAP-161 | Remove first letter - 'U'

export const getReportListColumn = ({
  Translation,
}: {
  Translation: (id: string) => string;
}): PruTableColumnDef<TalkbotReportListItem>[] => {
  return [
    {
      isId: true,
      hidden: true,
      keyIndex: 'wizCallId',
      displayName: '',
      renderData: () => '',
    },
    {
      keyIndex: 'exportDate',
      displayName: Translation('talkbot.export.date_time'),
      renderData: (row: TalkbotReportListItem) => getDefaultDisplayDate(row.exportDate, 'longDatetime'),
    },
    {
      keyIndex: 'unitCode',
      displayName: Translation('talkbot.unit_code'),
      renderData: (row: TalkbotReportListItem) => removeFirstLetterIfU(row.unitCode) || row.unitCode, // PACS Customization | PACSSGPPFAP-161 | Remove first letter - 'U'
    },
    {
      keyIndex: 'fcNumber',
      displayName: Translation('talkbot.fc_number'),
      renderData: (row: TalkbotReportListItem) => row.fcNumber,
    },
    {
      keyIndex: 'fcName',
      displayName: Translation('talkbot.fc_name'),
      renderData: (row: TalkbotReportListItem) => row.fcName || '-',
    },
    {
      keyIndex: 'reportingManagerFcNumber',
      displayName: Translation('talkbot.report.manager_fc_number'),
      renderData: (row: TalkbotReportListItem) => row.reportingManagerFcNumber,
    },
    {
      keyIndex: 'campaignId',
      displayName: Translation('agencyCampaign.common.campaignId'),
      renderData: (row: TalkbotReportListItem) => row.campaignId,
    },
    {
      keyIndex: 'campaignName',
      displayName: Translation('agencyCampaign.common.campaignName'),
      renderData: (row: TalkbotReportListItem) => row.campaignName,
    },
    {
      keyIndex: 'phoneNumber',
      displayName: Translation('pulseleads.common.phoneNumber'),
      renderData: (row: TalkbotReportListItem) => row.phoneNumber,
    },
    {
      keyIndex: 'strategyName',
      displayName: Translation('talkbot.strategy_name'),
      renderData: (row: TalkbotReportListItem) => row.strategyName,
    },
    {
      keyIndex: 'strategyId',
      displayName: Translation('talkbot.strategy_id'),
      renderData: (row: TalkbotReportListItem) => String(row.strategyId),
    },
    {
      keyIndex: 'wizCallId',
      displayName: Translation('talkbot.wiz_call_id'),
      renderData: (row: TalkbotReportListItem) => String(row.wizCallId),
    },
    {
      keyIndex: 'callDateTime',
      displayName: Translation('talkbot.call_date_time'),
      renderData: (row: TalkbotReportListItem) => getDefaultDisplayDate(row.callDateTime, 'longDatetime'),
    },
    {
      keyIndex: 'connectionStatus',
      displayName: Translation('talkbot.connection_status'),
      renderData: (row: TalkbotReportListItem) => row.connectionStatus,
    },
    {
      keyIndex: 'contactTags',
      displayName: Translation('talkbot.contact_tags'),
      renderData: (row: TalkbotReportListItem) => row.contactTags,
    },
  ];
};

export const removeEmptyValues = (obj: Record<string, any>) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== '' && value !== null && value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);
};

export const formatFilterState = (params: TalkbotReportFilterType): any => {
  const { reportMonth, ...rest } = params;
  const agentCode = rest.agentCode ? preFillZero(rest.agentCode) : undefined;
  const managerCode = rest.managerCode ? preFillZero(rest.managerCode) : undefined;
  const dateFrom = reportMonth ? dayjs(reportMonth).startOf('month').toDate() : rest.dateFrom;
  const dateTo = reportMonth ? dayjs(reportMonth).endOf('month').toDate() : rest.dateTo;
  const finalDateFrom = dateFrom ? getDayStart(dateFrom).toISOString() : undefined;
  const finalDateTo = dateTo ? getDayEnd(dateTo).toISOString() : undefined;
  return removeEmptyValues({
    ...rest,
    dateFrom: finalDateFrom,
    dateTo: finalDateTo,
    agentCode,
    managerCode,
  });
};
