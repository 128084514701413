import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import {
  CampaignFormMode,
  CampaignItem,
  CampaignDropdownType,
  CampaignDropdownList,
  ProspectCategoryItem,
} from 'src/app/modules/PulseLeads/types/campaign-types';
import {
  fetchCampaignItem,
  fetchCampaignDropdownList,
  fetchConfig,
  CampaignDropdownListParam,
  ConfigParam,
} from 'src/app/modules/PulseLeads/network/campaignCrud';
import CampaignForm from './components/CampaignForm';
import { getList as getProspectCategory } from '../../../Prospect/api';
import { fetchDialogueList } from 'src/app/modules/sales-kit/pages/ai-talkbot/network/talkbot-dialogue-crud';
import { DialogueItem } from 'src/app/modules/sales-kit/pages/ai-talkbot/types/talkbot-dialogue-type';

const CampaignDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? CampaignFormMode.EDIT : CampaignFormMode.CREATE,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaignItem, setCampaignItem] = useState<CampaignItem>();
  const [campaignDropdownList, setCampaignDropdownList] = useState<CampaignDropdownList>();
  const [config, setConfig] = useState<any>();
  const [salesCommonConfig, setSalesCommonConfig] = useState<any>();
  const [prospectCategory, setProspectCategory] = useState<ProspectCategoryItem[]>();
  const [talkbotDialogueList, setTalkbotDialogueList] = useState<DialogueItem[]>();

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const campaignItemRes = await fetchCampaignItem(id, dispatch);
        setCampaignItem(campaignItemRes);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    }
    try {
      const params: CampaignDropdownListParam = {
        type: [
          CampaignDropdownType.LEAD_SOURCE,
          CampaignDropdownType.ACCEPT_SLA,
          CampaignDropdownType.CONTACT_SLA,
          CampaignDropdownType.MEET_SLA,
          CampaignDropdownType.PROPOSAL_SLA,
          CampaignDropdownType.APPLICATION_SLA,
          CampaignDropdownType.LEAD_DEDUPLICATION_RULE,
          CampaignDropdownType.CUSTOMER_DEDUPLICATION_RULE,
          CampaignDropdownType.DISTRIBUTION_RULE,
          CampaignDropdownType.ELIGIBILITY_RULE,
          CampaignDropdownType.EXCLUSION_RULE,
          CampaignDropdownType.FEEDBACK,
          CampaignDropdownType.FEEDBACK_GROUP,
        ],
      };
      const dropdownListRes = await fetchCampaignDropdownList(params, dispatch);
      setCampaignDropdownList(dropdownListRes);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const reloadConfig = async () => {
    const configParams: ConfigParam = {
      name: 'sales-sdk',
      namespace: 'default',
    };
    const configRes = await fetchConfig(configParams, dispatch);
    setConfig(configRes.data[0]);
  };

  const reloadSalesCommonConfig = async () => {
    const configParams: ConfigParam = {
      name: 'sales-common',
      namespace: 'default',
    };
    const configRes = await fetchConfig(configParams, dispatch);
    setSalesCommonConfig(configRes.data[0]);
  };

  const reloadProspectCategory = async () => {
    const sortingListByType = (data: any) => {
      return data.sort((a: any, b: any) => a.type.charCodeAt(0) - b.type.charCodeAt(0));
    };
    getProspectCategory({}, dispatch).then((res: any) => {
      const docs = res.data?.docs ? sortingListByType(res.data.docs) : [];
      const data = docs.filter((item: ProspectCategoryItem) => item.type !== 'None');
      setProspectCategory(data);
    });
  };

  const reloadDialogueList = async () => {
    const list = await fetchDialogueList({}, dispatch);
    setTalkbotDialogueList(list);
  };

  useEffect(() => {
    reloadConfig();
    reloadSalesCommonConfig();
    reloadProspectCategory();
    reloadDialogueList();
  }, []);

  useEffect(() => {
    reloadData();
    reloadSalesCommonConfig();
    reloadProspectCategory();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <>
          {formMode === CampaignFormMode.CREATE &&
            (!config ? (
              <LayoutSplashScreen />
            ) : (
              <CampaignForm
                formMode={formMode}
                campaignDropdownList={campaignDropdownList}
                config={config}
                salesCommonConfig={salesCommonConfig}
                prospectCategoryList={prospectCategory}
                talkbotDialogueList={talkbotDialogueList}
              />
            ))}
          {formMode === CampaignFormMode.EDIT &&
            (isLoading || !campaignItem ? (
              <LayoutSplashScreen />
            ) : (
              <CampaignForm
                formMode={formMode}
                campaignDropdownList={campaignDropdownList}
                campaignItem={campaignItem}
                config={config}
                salesCommonConfig={salesCommonConfig}
                prospectCategoryList={prospectCategory}
                talkbotDialogueList={talkbotDialogueList}
              />
            ))}
        </>
      )}
    </>
  );
};

export default CampaignDetailPage;
