import { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useLang } from 'src/app/i18n';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getConfigurations, getDefaultDisplayDate } from 'src/app/common/utils';
import { AI_TALKBOT_PATH, AI_TALKBOT_STRATEGY_PATH, SALESKIT_BASE_PATH } from 'src/app/modules/sales-kit/constants';
import { DialogueWeightComponent } from '../dialogue-weight/dialogue-weight.component';
import { DialogueItem, DialogueKeyEnum, TalkbotFlowItem } from '../../../../../types/talkbot-dialogue-type';
import { get } from 'lodash';

type EventListProps = {
  isLoading: boolean;
  list: DialogueItem[];
  onSort: (sortObj: { key: string; value?: string }) => void;
  onRefresh: () => void;
};

export const DialogueListComponent: FC<EventListProps> = ({ isLoading, list, onSort, onRefresh }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();

  const existWeights = useMemo(() => {
    return list?.map((item) => item.weight);
  }, [list]);

  const flowIdList = useMemo(() => {
    const config = getConfigurations();
    return get(config, 'Sales.talkbot.dialogues') || [];
  }, []);

  return (
    <PruTable
      title={Translation('talkbot.dialogue_list')}
      disableBulkSelect
      disablePagination
      headerBtnDef={[
        {
          color: 'secondary',
          title: Translation('app.button.add'),
          onClick: () => history.push(`${SALESKIT_BASE_PATH}${AI_TALKBOT_PATH}${AI_TALKBOT_STRATEGY_PATH}/create`),
          disabled: isLoading,
        },
      ]}
      operationDef={[
        {
          title: Translation('section.common.operation.edit'),
          tooltipText: 'Edit',
          onClick: (row) =>
            history.push(`${SALESKIT_BASE_PATH}${AI_TALKBOT_PATH}${AI_TALKBOT_STRATEGY_PATH}/edit/${row._id}`),
        },
      ]}
      columnDef={[
        {
          isId: true,
          hidden: true,
          keyIndex: '_id',
          displayName: '',
          renderData: () => '',
        },
        {
          keyIndex: 'flowId',
          displayName: Translation('talkbot.dialogue.key'),
          renderData: (row) => {
            const dialogueKey: DialogueKeyEnum = flowIdList?.find(
              (item: TalkbotFlowItem) => item.flowId === row.flowId,
            )?.dialogueKey;
            return dialogueKey ? Translation(`talkbot.dialogue.key.${DialogueKeyEnum[dialogueKey]}`) : '';
          },
        },
        {
          keyIndex: 'weight',
          displayName: Translation('saleskit.companyPick.list.weight'),
          renderData: (row) => <DialogueWeightComponent row={row} existWeights={existWeights} />,
        },
        {
          keyIndex: 'isActive',
          displayName: Translation('talkbot.dialogue.status'),
          renderData: (row) =>
            row.isActive
              ? Translation('talkbot.dialogue.status.active')
              : Translation('talkbot.dialogue.status.inactive'),
        },

        {
          keyIndex: 'updatedBy',
          displayName: Translation('component.formLabel.last-updated-by'),
          renderData: (row) => row.updatedBy || '-',
        },
        {
          keyIndex: 'updatedAt',
          displayName: Translation('component.formLabel.last-updated-time'),
          renderData: (row) => getDefaultDisplayDate(row.updatedAt, 'datetime'),
          sortable: true,
          replaceSortState: true,
          onSort: (sort) => onSort({ key: 'updatedAt', value: sort['updatedAt'] }),
        },
      ]}
      isLoading={isLoading}
      dataSource={list}
      defaultRowsPerPage={20}
      onRefresh={onRefresh}
    />
  );
};
