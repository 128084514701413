import React, { FC, memo, useMemo, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { Autocomplete, Button, Checkbox, TextField } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import useFilterStyles from 'src/app/common/styles/filter-styles';
import { PruFilterDropdownItem } from 'src/app/common/components/PruTable/PruFilter';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import RangePicker from 'src/app/common/components/PruForm/DateRangePicker';
import { TalkbotReportFilterType, TalkbotReportListParam } from '../../../../../types/talkbot-report-type';
import { useDispatch } from 'react-redux';
import { removeFirstLetterIfU } from 'src/app/modules/AgencyCampaign/utils/common-utils'; // PACS Customization | PACSSGPPFAP-161 | Remove first letter - 'U'

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  unitCodeList: PruFilterDropdownItem[];
  filterState: TalkbotReportFilterType;
  changeFilterState: (key: keyof TalkbotReportFilterType, value: any) => void;
  onSearch: (params: TalkbotReportFilterType, dispatch?: any) => void;
  onReset: () => void;
};

export const ReportFilterComponent: React.FC<ComponentProps> = memo(
  ({ onReset, onSearch, unitCodeList, filterState, changeFilterState }) => {
    const { classes: commonClasses } = useCommonStyles();
    const { classes: filterClasses } = useFilterStyles();
    const dispatch = useDispatch();
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    return (
      <div style={{ marginBottom: 20 }} className={filterClasses.root}>
        {/* Header Row */}
        <div style={{ marginBottom: 5 }} className="PruFilter-header-container">
          <div className={commonClasses.header}>{Translation('talkbot.report.filter.title')}</div>
          <div className="PruFilter-row">
            <Button style={{ marginRight: 20 }} variant="contained" color="inherit" onClick={onReset}>
              {Translation('app.button.reset')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => onSearch(filterState, dispatch)}>
              {Translation('app.button.search')}
            </Button>
          </div>
        </div>

        {/* First Row */}
        <div className="PruFilter-row">
          <span className="PruFilter-criteria">{Translation('talkbot.report.month')} :</span>
          <PruDatePicker
            slotProps={{
              textField: {
                style: { marginRight: 20 },
              },
            }}
            format="MM/YYYY"
            value={filterState.reportMonth ?? null}
            views={['month', 'year']}
            onChange={(date) => changeFilterState('reportMonth', date)}
          />

          <span className="PruFilter-criteria">{Translation('talkbot.custom_call_date')} :</span>
          <RangePicker
            onChange={(newValue) => {
              changeFilterState('dateFrom', newValue[0]);
              changeFilterState('dateTo', newValue[1]);
            }}
            value={[filterState.dateFrom, filterState.dateTo]}
            needLabel={false}
          />
        </div>
        <div className="PruFilter-row">
          <span className="PruFilter-criteria">{Translation('talkbot.unit_code')} :</span>
          <Autocomplete
            style={{ marginRight: 20, width: 200 }}
            isOptionEqualToValue={(option, value) => option === value}
            // PACS Customization | PACSSGPPFAP-161 | Remove first letter - 'U' | Start
            getOptionLabel={(option) => {
              const displayName = unitCodeList.find((item) => item.value === option)?.displayName || option;
              return removeFirstLetterIfU(displayName) || displayName;
            }}
            // PACS Customization | PACSSGPPFAP-161 | Remove first letter - 'U' | End
            options={unitCodeList.map((option) => option.value)}
            value={filterState.unitCode}
            onChange={(e, newValue) => {
              changeFilterState('unitCode', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="dense"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />

          <span className="PruFilter-criteria">{Translation('talkbot.report.manager_fc_number')} :</span>
          <TextField
            style={{ marginRight: 20 }}
            margin="dense"
            variant="outlined"
            value={filterState.managerCode}
            onChange={(e) => changeFilterState('managerCode', e.target.value)}
          />

          <span className="PruFilter-criteria">{Translation('talkbot.fc_number')} :</span>
          <TextField
            style={{ marginRight: 20 }}
            margin="dense"
            variant="outlined"
            value={filterState.agentCode}
            onChange={(e) => changeFilterState('agentCode', e.target.value)}
          />
        </div>
      </div>
    );
  },
);
